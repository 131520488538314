import React from "react";
import { Container } from 'components/library';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { ArticleCard } from "components/common/article-card";
import { Newsletter } from "components/common/newsletter";
import { CtaBlock } from "components/common/cta-block";

type P = {
    pageContext: {
        articles: any[]
    }
}

const CaseStudiesNWhitePapers = ({ pageContext: { articles } }: P) => {
    const { t } = useTranslation()

    return (
        <main className='-mt-28 sm:-mt-36'>
            <section className='relative text-white flex flex-col gap-8 pb-12 sm:pb-16 pt-40 sm:pt-60 items-center text-center px-8 bg-hero-bg-flat-mobile sm:bg-hero-bg-flat bg-no-repeat bg-cover'>
                <h1 className='text-4xl sm:text-5xl md:text-6xl max-w-4xl'>
                    {t('case-studies-n-white-papers-page.heading')}
                </h1>
                <p className='max-w-4xl text-lg sm:text-xl'>
                    {t('case-studies-n-white-papers-page.desc')}
                </p>
            </section>

            <Container className='flex flex-col gap-20 py-24'>
                <section className='flex flex-col gap-12'>
                    <div className='grid grid-cols-1 md:flex gap-20'>
                        <div className='flex flex-col gap-8'>
                            <div className='flex flex-col gap-2'>
                                <h2 className='text-3xl text-theme-blue'>
                                    {t('common.words.case-study')}
                                </h2>
                                <span className='bg-gray-200 h-[2px]'></span>
                            </div>
                            <div className='flex h-full'>
                                <ArticleCard
                                    key={articles[0].id}
                                    className='max-w-full h-full md:max-w-[384px]'
                                    article={articles[0]}
                                    title={articles[0].title}
                                    excerpt={articles[0].excerpt}
                                    type="default"
                                    link='/case-study'
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-8'>
                            <div className='flex flex-col gap-2'>
                                <h2 className='text-3xl text-theme-blue'>
                                    {t('common.words.white-paper')}
                                </h2>
                                <span className='bg-gray-200 h-[2px]'></span>
                            </div>
                            <div className='flex h-full'>
                                <ArticleCard
                                    key={articles[1].id}
                                    className='max-w-full h-full md:max-w-[384px]'
                                    article={articles[1]}
                                    title={t('case-studies-n-white-papers-page.white-paper.title')}
                                    excerpt={t('case-studies-n-white-papers-page.white-paper.excerpt')}
                                    type="default"
                                    link='/white-paper'
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <Newsletter />
            </Container>

            {/* ONLY FOR GOOGLE INDEXING */}
            <Link to="/download-document/verify" className="invisible">
                Document Verification
            </Link>

            <CtaBlock />
        </main>
    )
};

export default CaseStudiesNWhitePapers

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 
